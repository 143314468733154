import Prism from '@theme-ui/prism'
import {
  ColorBlock,
  ContentFull,
  ContentMain,
  Headline,
  HeroLead,
  ImageSvg,
  Summary,
  Title,
} from '../components/Content'
import { HeroImage } from '../components/HeroImage'
import { ContactForm } from '../components/ContactForm'
import { ContactBanner } from '../components/ContactBanner'
import { LinkSymbol } from '../components/SerializerLink'
import { OptimizelyToggler, SplitToggler } from '../components/FeatureToggler'

export default {
  ColorBlock,
  ContactForm,
  ContactBanner,
  ContentFull,
  ContentMain,
  Headline,
  HeroLead,
  HeroImage,
  ImageSvg,
  LinkSymbol,
  OptimizelyToggler,
  SplitToggler,
  Summary,
  Title,
  pre: (props) => props.children,
  code: Prism,
}
