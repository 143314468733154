import React from 'react'
import PropTypes from 'prop-types'
import { OptimizelyProvider, createInstance } from '@optimizely/react-sdk'

const optimizely = createInstance({
  sdkKey: process.env.OPTIMIZELY_SDK_KEY,
})

const wrapRootElement = ({ element }) => (
  <OptimizelyProvider optimizely={optimizely} timeout={500} user={{ id: 'ssr' }}>
    {element}
  </OptimizelyProvider>
)

export default wrapRootElement

wrapRootElement.propTypes = {
  element: PropTypes.element.isRequired,
}
