/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { useIntl, Link } from 'gatsby-plugin-intl'
import PropTypes from 'prop-types'

const ContactBanner = ({ content }) => {
  const intl = useIntl()
  const { locale } = intl

  return (
    <div
      sx={{
        display: `grid`,
        gridTemplateColumns: [`1fr`, `1fr 1fr`, `2fr 1fr`, `3fr 1fr`],
        gridGapColumns: 4,
        bg: `hsla(308, 55%, 25%, 1)`,
        color: `background`,
        p: 6,
      }}
    >
      <p>{content.text}</p>
      <p>
        <Link
          to={content.link}
          sx={{
            boxShadow: `unset`,
            color: `text`,

            border: `2px solid`,
            borderColor: `background`,
            borderRadius: 4,
            bg: `background`,
            py: 3,
            px: 4,

            transition: `0.4s all ease-out`,

            textDecoration: `none`,
            ':hover': {
              boxShadow: `unset`,
              //borderBottom: `1px solid LavenderBlush`,
              transition: `0.4s all ease-out`,
              color: `background`,
              borderColor: `text`,
              bg: `text`,
            },
          }}
        >
          {content.buttonText ? content.buttonText : intl.formatMessage({ id: 'company' })}
        </Link>
      </p>
    </div>
  )
}

ContactBanner.propTypes = {
  data: PropTypes.object.isRequired,
}

export { ContactBanner }
