/* @jsx jsx */
import React from 'react'
import PropTypes from 'prop-types'
import { jsx } from 'theme-ui'
import styled from '@emotion/styled'

const ColorBlock = ({ children, color, bg }) => (
  <div
    sx={{
      my: [4, 5, null, 6],
      mx: [0, null, `-32px`, `-64px`],
      py: [3, 4, null, 5],
      px: [3, 4, null, 5],
      borderRadius: 4,
      color: color ? color : `background`,
      bg: bg ? bg : `primary`,
      fontFamily: `heading`,
      fontSize: [`1.25rem`, null, `1.5rem`],
      fontWeight: 200,
      lineHeight: [`1.5rem`, null, `1.875rem`],
      hyphens: `auto`,
      '& h2': {
        color: color ? color : `background`,
        mt: 0,
      },
      '& p:last-of-type': {
        mb: [2, 3],
      },
    }}
  >
    {children}
  </div>
)

const ContentFull = ({ children, mt, mb }) => (
  <div
    test-dataid="contentMainBlock"
    sx={{
      gridColumn: `1/-1`,
      mt: mt && 5,
      mb: mb && 5,
    }}
  >
    {children}
  </div>
)

const ContentMain = ({ children, mt, mb }) => (
  <div
    test-dataid="contentMainBlock"
    sx={{
      gridColumn: `main`,
      mt: mt && 5,
      mb: mb && 5,
    }}
  >
    {children}
  </div>
)

const Headline = ({ children }) => (
  <h1
    aria-label="Headline"
    sx={{
      gridColumn: `main`,
      //bg: `PeachPuff`,
    }}
  >
    {children}
  </h1>
)

const HeroLead = ({ bg, children, color, herotitle, ...props }) => {
  const Wrapper = styled.h2({})

  return (
    <div
      sx={{
        display: `flex`,
        gridColumn: [`1/-1`, null, `2/4`],
        gridRow: 1,
        maxHeight: [null, null, `75vh`],
        minHeight: [null, null, `20rem`],
        m: [`32px -16px`, null, `0 -32px`],
        bg: bg ? bg : `accent`,
        zIndex: 3,
        alignItems: `center`,
      }}
    >
      {herotitle ? (
        <div
          sx={{
            p: [3, null, 0],
            px: [3, 4, 4],
            pt: [4, null, 6],
          }}
        >
          <p
            sx={{
              fontFamily: `heading`,
              fontWeight: 200,
              color: color ? color : `primary`,
              m: 0,
              p: 0,
            }}
          >
            {herotitle}
          </p>
          <Wrapper
            sx={{
              fontFamily: `heading`,
              fontSize: `1.25rem`,
              fontSize: [null, null, `3rem`],
              color: color ? color : `primary`,
            }}
            {...props}
          >
            {children}
          </Wrapper>
        </div>
      ) : (
        <Wrapper
          sx={{
            fontFamily: `heading`,
            //fontSize: landing ? `1.75rem` : `1.25rem`,
            //fontWeight: 200,
            //lineHeight: landing ? `2rem` : `1.5rem`,
            hyphens: [`auto`, `manual`],
            fontSize: [null, null, `3rem`],
            color: color ? color : `primary`,
            p: [3, null, 0],
            px: [3, 4, 4],
            pt: [4, null, 6],
          }}
          {...props}
        >
          {children}
        </Wrapper>
      )}
    </div>
  )
}

const ImageSvg = ({ src, alt, ...props }) => {
  const Wrapper = styled.img({})
  return <Wrapper {...props} src={src} alt={alt ? alt : ''} />
}

const LandingHeadline = ({ children }) => (
  <h2
    sx={{
      gridColumn: `main`,
      fontFamily: `heading`,
      fontSize: `1.75rem`,
      color: `primary`,
      lineHeight: `2rem`,
      hyphens: [`auto`, `manual`],
      mt: `1.75rem`,
      mb: `2.25rem`,
    }}
  >
    {children}
  </h2>
)

const ProjectHeadline = ({ children }) => (
  <h2
    sx={{
      gridColumn: `main`,
      fontFamily: `heading`,
      fontSize: `1.75rem`,
      color: `primary`,
      lineHeight: `2rem`,
      hyphens: [`auto`, `manual`],
      mt: `1.75rem`,
      mb: `2.25rem`,
    }}
  >
    {children}
  </h2>
)

const LandingList = ({ children, color }) => (
  <ul
    sx={{
      //gridColumn: `main`,
      fontFamily: `heading`,
      fontSize: `1.25rem`,
      fontWeight: 200,
      color: color ? color : `primary`,
      lineHeight: `1.625rem`,
      hyphens: [`auto`, `manual`],
      marginBottom: `4rem`,
      //pb: [4, null, 5],
      listStyleType: `square`,
      '> li': {
        marginBottom: `1.625rem`,
        color: `text`,
      },
    }}
  >
    {children}
  </ul>
)

const Summary = ({ landing, children, color }) => (
  <p
    aria-label="Summary"
    sx={{
      gridColumn: `main`,
      fontFamily: `heading`,
      fontSize: landing ? `1.75rem` : `1.25rem`,
      fontWeight: 200,
      color: color ? color : landing ? `background` : `#002856`,
      lineHeight: landing ? `2rem` : `1.5rem`,
      hyphens: [`auto`, `manual`],
      marginBottom: `1.00781rem`,
      pb: [4, null, 5],
      //bg: `Gold`,
    }}
  >
    {children}
  </p>
)

const Title = ({ children }) => (
  <p sx={{ m: 0, p: 0, fontFamily: `heading`, fontWeight: 200 }}>{children}</p>
)

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
}

ColorBlock.propTypes = {
  ...propTypes,
  bg: PropTypes.string,
}

ContentFull.propTypes = {
  ...propTypes,
  mt: PropTypes.bool,
  mb: PropTypes.bool,
}

ContentMain.propTypes = {
  ...propTypes,
  mt: PropTypes.bool,
  mb: PropTypes.bool,
}

Headline.propTypes = propTypes

HeroLead.propTypes = propTypes

ImageSvg.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
}

LandingHeadline.propTypes = propTypes

LandingList.propTypes = {
  ...propTypes,
  color: PropTypes.string,
}

Summary.propTypes = {
  ...propTypes,
  landing: PropTypes.bool,
}

Title.propTypes = propTypes

export {
  ColorBlock,
  ContentFull,
  ContentMain,
  Headline,
  HeroLead,
  ImageSvg,
  LandingHeadline,
  LandingList,
  ProjectHeadline,
  Summary,
  Title,
}
