/** @jsx jsx */
import React, { useState } from 'react'
import { jsx } from 'theme-ui'
import { useIntl } from 'gatsby-plugin-intl'
import { useTreatments } from 'gatsby-plugin-splitio'
import { Formik, Form, useField } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'

const ContactForm = ({ headline, lead }) => {
  const intl = useIntl()
  const { locale } = intl

  const [showForm, setShowForm] = useState(true)

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().max(255, intl.formatMessage({ id: `tooLong` })),
    email: Yup.string()
      .email(intl.formatMessage({ id: `invalid` }))
      .required(intl.formatMessage({ id: `required` })),
  })

  const sendMessage = async ({ email, fullName, location, ...props }) => {
    const details = JSON.stringify(props)
    const formData = {
      subject: `[Sylox] Contact Form Request`,
      email: email,
      text: `
        Name: ${fullName}
        Email: ${email}
        Details: ${details}
        URL: ${location}
        Spam: ${props.emailRepeat || props.lastName ? 'true' : 'checked'}
      `,
    }
    await fetch(`/api/sendMail`, {
      method: `POST`,
      body: JSON.stringify(formData),
    })
    setShowForm(false)
  }

  const feature = ['COMPANY_FIELD']
  const treatments = useTreatments(feature)
  const compField = treatments[feature].treatment === 'on'

  return (
    <div
      sx={{
        gridColumn: `1 / -1`,
        px: [3, null, 0],
        py: `5vh`,
        color: showForm ? `text` : `background`,
        bg: showForm ? `gray.5` : `primary`,
        borderRadius: 4,
        display: `grid`,
        gridTemplateColumns: [`1fr`, null, `0.5fr 0.5fr`],
        gridTemplateAreas: [
          `"allIn"`,
          null,
          `"headline headline"
           "greeting form"`,
        ],
        paddingLeft: [3, null, `128px`],
        gridColumnGap: [4, null, null, 5],
      }}
    >
      {showForm ? (
        <>
          <h2
            aria-label="headline"
            sx={{
              gridColumn: [`allIn`, null, `headline`],
              mt: 0,
              color: `text`,
            }}
          >
            {headline ? headline[locale] : intl.formatMessage({ id: `contactHeadline` })}
          </h2>
          <p aria-label="lead" sx={{ color: `text`, gridColumn: [`allIn`, null, `greeting`] }}>
            {lead ? lead[locale] : intl.formatMessage({ id: `contactLead` })}
          </p>
          <div
            sx={{
              gridColumn: [`allIn`, null, `form`],
              justifyItems: `flex-end`,
              mr: [null, null, 4],
            }}
          >
            <Formik
              initialValues={{
                email: '',
                fullName: '',
                location: typeof window !== `undefined` && location.href,
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                sendMessage(values)
              }}
            >
              {({ errors, touched }) => {
                return (
                  <Form role="form" id="contactForm" sx={{ m: 0 }}>
                    <TextField
                      data-testid="email"
                      name="email"
                      type="email"
                      placeholder={intl.formatMessage({ id: `email` })}
                      label={
                        errors.email && touched.email
                          ? errors.email
                          : intl.formatMessage({ id: `email` })
                      }
                    />
                    <TextField
                      data-testid="fullName"
                      name="fullName"
                      type="text"
                      placeholder={intl.formatMessage({ id: `fullName` })}
                      label={
                        errors.fullName && touched.fullName
                          ? errors.fullName
                          : intl.formatMessage({ id: `fullName` })
                      }
                    />
                    {compField && (
                      <TextField
                        data-testid="company"
                        name="company"
                        type="text"
                        placeholder={intl.formatMessage({ id: `company` })}
                        label={intl.formatMessage({ id: `company` })}
                      />
                    )}
                    <TextField
                      sx={{ variant: `additionalFields` }}
                      data-testid="emailRepeat"
                      name="emailRepeat"
                      type="email"
                      placeholder="Email repeat"
                      label={intl.formatMessage({ id: `iLoveHoney` })}
                      ilh
                      tabIndex="-1"
                    />
                    <TextField
                      sx={{ variant: `additionalFields` }}
                      data-testid="lastName"
                      name="lastName"
                      type="test"
                      placeholder="Last name"
                      label={intl.formatMessage({ id: `iLoveHoney` })}
                      ilh
                      tabIndex="-1"
                    />
                    <TextField name="location" type="hidden" />
                    <button
                      aria-label="contact-button"
                      type="submit"
                      sx={{
                        width: `100%`,
                        border: `1px solid`,
                        borderColor: `primary`,
                        borderRadius: 4,
                        fontFamily: `heading`,
                        fontSize: `1.125rem`,
                        color: `background`,
                        bg: `primary`,
                        padding: `10px`,
                        mt: 3,
                      }}
                    >
                      {intl.formatMessage({ id: `contactButton` })}
                    </button>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </>
      ) : (
        <p
          aria-label="Thank you for your message"
          sx={{
            gridColumn: [`allIn`, null, `1/-1`],
            maxWidth: `640px`,
          }}
        >
          {intl.formatMessage({ id: `contactThanks` })}
        </p>
      )}
    </div>
  )
}

ContactForm.propTypes = {
  headline: PropTypes.object,
  lead: PropTypes.object,
}

function TextField({ label, ilh, ...props }) {
  const [field, meta] = useField(props)

  return (
    <div sx={{ mb: 2 }}>
      <label>
        {label && (
          <span
            className={ilh && `iAmIlH`}
            sx={{
              fontFamily: `heading`,
              color: `text`,
              px: 2,
            }}
          >
            {label}
          </span>
        )}
        <input
          {...field}
          {...props}
          sx={{
            display: `block`,
            width: `100%`,
            border: `1px solid`,
            borderColor: `primary`,
            padding: `10px`,
            borderRadius: 4,
            bg: meta.touched && meta.error ? `error.background` : `background`,
            ':focus': {
              borderColor: `background`,
            },
            '::placeholder': {
              color: meta.touched && meta.error ? `error.placeholder` : `text`,
            },
          }}
        />
      </label>
    </div>
  )
}

TextField.propTypes = {
  label: PropTypes.string,
  ilh: PropTypes.bool,
}

export { ContactForm }
