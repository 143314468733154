exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-insights-jsx": () => import("./../../../src/pages/insights.jsx" /* webpackChunkName: "component---src-pages-insights-jsx" */),
  "component---src-pages-landing-deliver-highspeed-jsx": () => import("./../../../src/pages/landing/deliver-highspeed.jsx" /* webpackChunkName: "component---src-pages-landing-deliver-highspeed-jsx" */),
  "component---src-pages-landing-effective-remote-teams-jsx": () => import("./../../../src/pages/landing/effective-remote-teams.jsx" /* webpackChunkName: "component---src-pages-landing-effective-remote-teams-jsx" */),
  "component---src-templates-mdx-entry-js-content-file-path-home-runner-work-sylox-sylox-www-src-content-about-en-mdx": () => import("./../../../src/templates/mdxEntry.js?__contentFilePath=/home/runner/work/sylox/sylox/www/src/content/about.en.mdx" /* webpackChunkName: "component---src-templates-mdx-entry-js-content-file-path-home-runner-work-sylox-sylox-www-src-content-about-en-mdx" */),
  "component---src-templates-mdx-entry-js-content-file-path-home-runner-work-sylox-sylox-www-src-content-about-legal-dataprotection-en-mdx": () => import("./../../../src/templates/mdxEntry.js?__contentFilePath=/home/runner/work/sylox/sylox/www/src/content/about/legal/dataprotection.en.mdx" /* webpackChunkName: "component---src-templates-mdx-entry-js-content-file-path-home-runner-work-sylox-sylox-www-src-content-about-legal-dataprotection-en-mdx" */),
  "component---src-templates-mdx-entry-js-content-file-path-home-runner-work-sylox-sylox-www-src-content-about-legal-imprint-de-mdx": () => import("./../../../src/templates/mdxEntry.js?__contentFilePath=/home/runner/work/sylox/sylox/www/src/content/about/legal/imprint.de.mdx" /* webpackChunkName: "component---src-templates-mdx-entry-js-content-file-path-home-runner-work-sylox-sylox-www-src-content-about-legal-imprint-de-mdx" */),
  "component---src-templates-mdx-entry-js-content-file-path-home-runner-work-sylox-sylox-www-src-content-contact-de-mdx": () => import("./../../../src/templates/mdxEntry.js?__contentFilePath=/home/runner/work/sylox/sylox/www/src/content/contact.de.mdx" /* webpackChunkName: "component---src-templates-mdx-entry-js-content-file-path-home-runner-work-sylox-sylox-www-src-content-contact-de-mdx" */),
  "component---src-templates-mdx-entry-js-content-file-path-home-runner-work-sylox-sylox-www-src-content-insights-leadership-covid-19-en-mdx": () => import("./../../../src/templates/mdxEntry.js?__contentFilePath=/home/runner/work/sylox/sylox/www/src/content/insights/leadership-covid19.en.mdx" /* webpackChunkName: "component---src-templates-mdx-entry-js-content-file-path-home-runner-work-sylox-sylox-www-src-content-insights-leadership-covid-19-en-mdx" */),
  "component---src-templates-mdx-entry-js-content-file-path-home-runner-work-sylox-sylox-www-src-content-insights-what-is-a-safe-space-en-mdx": () => import("./../../../src/templates/mdxEntry.js?__contentFilePath=/home/runner/work/sylox/sylox/www/src/content/insights/what-is-a-safe-space.en.mdx" /* webpackChunkName: "component---src-templates-mdx-entry-js-content-file-path-home-runner-work-sylox-sylox-www-src-content-insights-what-is-a-safe-space-en-mdx" */),
  "component---src-templates-mdx-entry-js-content-file-path-home-runner-work-sylox-sylox-www-src-content-services-de-mdx": () => import("./../../../src/templates/mdxEntry.js?__contentFilePath=/home/runner/work/sylox/sylox/www/src/content/services.de.mdx" /* webpackChunkName: "component---src-templates-mdx-entry-js-content-file-path-home-runner-work-sylox-sylox-www-src-content-services-de-mdx" */)
}

