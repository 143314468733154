/** @jsx jsx */
import React from 'react'
import PropTypes from 'prop-types'
import { jsx } from 'theme-ui'
import { FiExternalLink } from 'react-icons/fi'

const LinkSymbol = () => (
  <span sx={{ '& svg': { height: `14px`, position: `relative`, top: `.05em` } }}>
    <FiExternalLink />
  </span>
)

const SerializerLink = ({ mark: { blank, href }, children }) =>
  blank ? (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
      <LinkSymbol />
    </a>
  ) : (
    <a href={href}>
      {children}
      <LinkSymbol />
    </a>
  )

SerializerLink.propTypes = {
  mark: PropTypes.shape({
    blank: PropTypes.bool,
    href: PropTypes.string,
  }),
  children: PropTypes.node,
}

export { SerializerLink }
export { LinkSymbol }
