/* @jsx jsx */
import styled from '@emotion/styled'
import { GatsbyImage } from 'gatsby-plugin-image'
import { jsx } from 'theme-ui'

const HeroImage = ({ image, fluid, src, bg, ...props }) => {
  const ImageWrapper = styled(GatsbyImage)({})

  return (
    <ImageWrapper
      image={image || fluid || src}
      sx={{
        gridColumn: `1/-1`,
        gridRow: 1,
        maxHeight: [null, null, `75vh`],
        minHeight: [null, null, `20rem`],
        borderRadius: [0, null, 4],
        bg: bg ? bg : `tertiary`,
        mx: [`-16px`, null, 0],
      }}
      {...props}
    />
  )
}

export { HeroImage }
