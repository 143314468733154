/** @jsx jsx */
import React from 'react'
import PropTypes from 'prop-types'
import { jsx } from 'theme-ui'
import { OptimizelyFeature } from '@optimizely/react-sdk'
import { SplitTreatments } from 'gatsby-plugin-splitio'

const OptimizelyToggler = ({ feature, children }) => {
  const count = React.Children.count(children)

  return (
    <OptimizelyFeature feature={feature}>
      {(isEnabled) => (isEnabled && count === 1 ? children : isEnabled ? children[0] : children[1])}
    </OptimizelyFeature>
  )
}

const SplitToggler = ({ feature, children }) => {
  const count = React.Children.count(children)

  return (
    <SplitTreatments names={[feature]}>
      {({ treatments, isReady, isTimedout }) => {
        const featureState = treatments[feature].treatment
        return (
          isReady &&
          !isTimedout &&
          (count === 1
            ? featureState === 'on' && children
            : featureState === 'on'
            ? children[0]
            : children[1])
        )
      }}
    </SplitTreatments>
  )
}

const propTypes = {
  feature: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
}
OptimizelyToggler.propTypes = propTypes
SplitToggler.propTypes = propTypes

export { OptimizelyToggler, SplitToggler }
