import prismTheme from '@theme-ui/prism/presets/prism-tomorrow.json'

export default {
  colors: {
    text: `hsl(212, 100%, 5%)`,
    background: `#fff`,
    primary: `hsl(212, 100%, 16%)`,
    secondary: `hsl(340, 76%, 48%)`,
    //tertiary: `hsl(138, 51%, 63%)`, // original
    tertiary: `hsl(161, 26%, 66%)`, // cambridge blue
    tertiary1: `hsl(152, 100%, 76%)`, //aqaumarine
    tertiary2: `hsl(168, 100%, 50%)`, //sea green grayola
    tertiary3: `hsl(113, 79%, 42%)`, // kelly green
    muted: `hsl(211, 16%, 68%)`,
    accent: `hsla(45, 100%, 50%, 1)`,
    danger: `hsl(10,100%,50%)`,
    navigation: `hsl(333, 50%, 8%)`,
    error: {
      placeholder: `hsl(333, 95%, 25%)`,
      background: `hsl(333, 50%, 89%)`,
    },
    gray: [
      `hsl(333, 5%, 15%)`,
      `hsl(333, 5%, 35%)`,
      `hsl(333, 5%, 55%)`,
      `hsl(333, 5%, 75%)`,
      `hsl(333, 5%, 85%)`,
      `hsl(333, 5%, 95%)`,
    ],
    modes: {
      dark: {
        text: `#fff`,
        background: `#1c1c1c`,
        primary: `#F52887`,
        secondary: `#4EE2EC`,
        muted: `lightgray`,
        accent: `secondary`,
        danger: `#d13b40`,
        gray: [`whitesmoke`, `gainsboro`, `lightgray`, `silver`, `darkgray`, `gray`],
      },
    },
  },
  fonts: {
    body: `tablet-gothic-narrow, 'Archivo Narrow', Verdana, sans-serif`,
    heading: `tablet-gothic-narrow, 'Archivo Narrow', Verdana, sans-serif`,
    summary: `tablet-gothic-narrow, 'Archivo Narrow', Verdana, sans-serif`,
    monospace: `Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace`,
  },
  fontWeights: {
    body: 300,
    heading: 600,
    bold: 600,
  },
  /* eslint-disable-next-line max-len */
  fontSizes: [14, 16, 18, 20, 24, 32, 48, 64, 72, 96],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: [600, 900, 1200, 1500].map((n) => n + `px`),
  sizes: {
    container: {
      fullscreen: `100%`,
      largeplus: `calc(920px + 20vw)`,
      large: `920px`,
      smallplus: `calc(640px + 10vw)`,
      default: `640px`,
      aside: `400px`,
    },
    page: {
      default: `1280px`,
    },
  },
  buttons: {
    color: `text`,
    //borderColor: `text`,
    //border: `2px solid`,
    bg: `background`,
    px: 4,
    py: 2,
    fontWeight: `heading`,
    '&:hover': {
      //color: `background`,
      //bg: `text`,
      //transition: `0.5 ease-out all`,
    },
    large: {
      border: `3px solid`,
      px: 6,
      py: 3,
      fontSize: 5,
      fontWeight: `heading`,
    },
    small: {
      //border: `2px solid`,
      border: `0`,
      //borderColor: `text`,
      //color: `text`,
      bg: `transparent`,
      textDecoration: `none`,
      px: 0,
      py: 1,
      fontSize: 2,
      fontWeight: `text`,
      ':hover': {
        //color: `background`,
        //bg: `text`,
      },
    },
  },
  styles: {
    a: {
      boxShadow: `inset 0 -1px 0 hsla(205, 100%, 50%, 1)`,
      borderBottom: `1px solid hsla(205, 100%, 50%, 1)`,
      transition: `0.4s all ease-in`,
      color: `inherit`,
      textDecoration: `none`,
      ':hover': {
        boxShadow: `inset 0 -32px 0 hsla(205, 100%, 50%, 0.2)`,
        borderBottom: `1px solid hsla(205, 100%, 50%, 0.2)`,
        transition: `0.4s all ease-in`,
      },
    },
    code: {
      ...prismTheme,
      fontSize: `0.75rem`,
    },
    pre: {
      //fontSize: `0.75rem`,
      '&.prism-code': {
        p: [2, 3],
      },
      '&.language-sh > div': {
        ':before': {
          content: `"$ "`,
        },
      },
      '&.highlight': {
        background: 'hsla(0, 0%, 30%, .5)',
      },
    },
    root: {
      minHeight: `100%`,
      'h1,h2,h3': {
        color: '#002856',
      },
      h1: {
        fontWeight: 700,
        fontSize: `2.125rem`,
        hyphens: [`auto`, `manual`],
      },
      'h2,h3': {
        //fontWeight: 600,
        hyphens: [`auto`, `manual`],
        marginTop: `2.58rem`,
        marginBottom: `1.00781rem`,
      },
      'summary,.tldr': {
        fontFamily: `heading`,
        fontSize: `1.25rem`,
        fontWeight: 200,
        color: `#002856`,
        lineHeight: `1.5rem`,
        hyphens: `auto`,
        marginBottom: `1.00781rem`,
        '&.landing': {
          fontSize: `1.75rem`,
          lineHeight: `2rem`,
          color: `background`,
        },
      },
      p: {
        mb: `1rem`,
      },
      strong: {
        color: `primary`,
      },
      blockquote: {
        fontFamily: `heading`,
        fontSize: `1.25rem`,
        //fontStyle: 'italic',
        color: `secondary`,
        borderLeft: `0.25rem solid`,
        borderColor: `secondary`,
        my: [4, 5],
        mx: [0, null, `-32px`, `-64px`],
        py: `0.75rem`,
        pr: [`0.75rem`, null, 0],
        pl: [`0.75rem`, null, `calc(32px - 0.25rem)`, `calc(64px - 0.25rem)`],
        '& strong': {
          color: `secondary`,
        },
        '& p': {
          mb: 0,
        },
      },
      'blockquote > :last-child': {
        mb: 0,
      },
      'code,kbd,pre,samp': {
        fontFamily: `monospace`,
        lineHeight: `1.6125`,
      },
      /** 
    a: {
      boxShadow: `inset 0 -1px 0 hsla(205, 100%, 50%, 1)`,
      borderBottom: `1px solid hsla(205, 100%, 50%, 1)`,
      transition: `0.4s all ease-in`,
      color: `inherit`,
      textDecoration: `none`,
      ':hover': {
        boxShadow: `inset 0 -32px 0 hsla(205, 100%, 50%, 0.2)`,
        borderBottom: `1px solid hsla(205, 100%, 50%, 0.2)`,
        transition: `0.4s all ease-in`,
      },
    },
    */
      ul: {
        listStyleType: `square`,
      },
      '.gatsby-resp-image-image': {
        bg: `primary`,
        borderRadius: 4,
      },
      '.iAmIlH': {
        fontSize: `0px`,
        lineHeight: 0,
        visibility: `hidden`,
        display: `none`,
      },
    },
  },

  content: {
    main: {
      width: `100%`,
      m: `0 auto`,
      bg: `fuchsia`,
      gridColumn: `main`,
      headline: {
        borderTop: [null, null, `0.25rem solid`],
        borderColor: `text`,
        mt: [3, 4, 5],
        mb: 0,
        py: `0.75rem`,
      },
      block: {
        gridColumn: `main`,
        m: 0,
        p: 0,
        image: {
          px: 0,
        },
        lead: {
          borderBottom: `1px solid`,
          boderColor: `text`,
          pb: `0.75rem`,
        },
      },
    },
  },
  header: {
    langLink: {
      border: `2px solid`,
      borderColor: `background`,
      //borderLeft: `1px solid`,
      //borderLeftColor: `text`,
      backgroundColor: `transparent`,
      fontFamily: `heading`,
      fontWeight: 200,
      mb: [0, null, 2],
      pb: [`3px`, null, 0],
      px: [2, null, 3],
      ':hover': {
        textShadow: `0 0 1px black`,
        border: `2px solid`,
        borderColor: `background`,
      },
    },
    navlink: {
      mb: [0, null, 2],
      pb: [`3px`, null, 0],
      px: [2, null, 3],
      fontFamily: `heading`,
      fontWeight: 400,
      textDecoration: `none`,
      color: `navigation`,
      borderBottom: `unset`,
      border: `2px solid`,
      borderColor: `background`,
      boxShadow: `unset`,
      transition: `1s ease-in all`,
      ':hover': {
        boxShadow: `unset`,
        borderBottom: `unset`,
        border: `2px solid`,
        borderColor: `background`,
        color: `secondary`,
        transition: `1s ease-in all`,
      },
      ':active, &.active': {
        color: [`navigation`, null, `navigation`],
        fontWeight: [600, null, 400],
        bg: [`transparent`, null, `transparent`],
        border: `2px solid`,
        borderColor: [`background`, null, `navigation`],
        borderRadius: `4px`,
        transition: `1s ease-in all`,
        boxShadow: `unset`,
      },
    },
    burgerlink: {
      ml: 3,
      pt: 1,
      pb: 2,
      fontFamily: `heading`,
      fontWeight: 400,
      fontSize: [4, 5],
      textDecoration: `none`,
      color: `background`,
      boxShadow: `unset`,
      border: `unset`,
      ':hover': {
        boxShadow: `unset`,
        border: `unset`,
        textDecoration: `underline`,
        transition: `1s ease-in all`,
      },
      '&.active': {
        boxShadow: `unset`,
        border: `unset`,
        fontWeight: `bold`,
      },
    },
  },
  footer: {
    footerLink: {
      boxShadow: `unset`,
      border: `unset`,
      ml: [0, 3, 4],
      fontWeight: 300,
      fontFamily: `heading`,
      fontSize: [1, 1, 2],
      textDecoration: `none`,
      color: `text`,
      ':hover': {
        boxShadow: `unset`,
        border: `unset`,
        color: `primary`,
      },
      '&.active': {
        boxShadow: `unset`,
        border: `unset`,
        color: `primary`,
      },
    },
    footerText: {
      fontFamily: `heading`,
      fontSize: [1, 1, 2],
      fontWeight: 300,
      color: `text`,
    },
  },
  additionalFields: {
    opacity: 0,
    position: `absolute`,
    top: 0,
    left: 0,
    height: 0,
    width: 0,
    zIndex: -1,
  },
  contentGrid: {
    display: `grid`,
    gridTemplateColumns: [`minmax(0, 640px)`, null, `96px 640px 1fr minmax(auto, 336px)`],
    gridTemplateAreas: [`"main"`, null, `"banner banner banner banner" "lefty main righty aside"`],
    gridTemplateRows: `auto`,
    gridColumnGap: [0, null, `32px`],
    gridAutoFlow: `column`,
    px: 3,
  },
  landingGrid: {
    display: `grid`,
    gridTemplateColumns: [`minmax(0, 720px)`, null, `96px 720px 1fr minmax(auto, 336px)`],
    gridTemplateAreas: [`"main"`, null, `"banner banner banner banner" "lefty main righty aside"`],
    gridTemplateRows: `auto`,
    gridColumnGap: [0, null, `32px`],
    gridAutoFlow: `column`,
    px: 3,
  },
  heroImage: {
    gridColumn: `1/-1`,
    gridRow: 1,
    maxHeight: `75vh`,
    minHeight: `20rem`,
    borderRadius: 4,
    bg: `hsl(333, 100%, 30%)`,
  },
}
